<template>
  <div>
    <!-- <Toolbar class="p-mb-4">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/user/players/' + body.leaguesId)"
        />
      </template>
    </Toolbar> -->
    <div class="form-card">
      <div class="mb-3">
        <label for="nationalID" class="form-label">
          الرقم القومي <span style="color: red">*</span>
        </label>
        <input class="form-control" id="nationalID" v-model="body.nationalID" />
      </div>

      <div v-if="canAddInfo">
        <div class="mb-3">
          <label for="name" class="form-label">
            الاسم <span style="color: red">*</span>
          </label>
          <input class="form-control" id="name" v-model="body.name" />
        </div>

        <div class="mb-3">
          <label for="name" class="form-label">
            الاسم بالأنجليزيه <span style="color: red">*</span>
          </label>
          <input class="form-control" id="nameEn" v-model="body.nameEn" />
        </div>

        <div class="mb-3">
          <label for="name" class="form-label">
            اسم المدرسه <span style="color: red">*</span>
          </label>
          <input
            class="form-control"
            id="schoolName"
            v-model="body.schoolName"
          />
        </div>

        <div class="mb-3">
          <label for="dateOfBirth" class="form-label">
            تاريخ الميلاد <span style="color: red">*</span>
          </label>
          <input
            class="form-control"
            id="dateOfBirth"
            disabled
            v-model="body.dateOfBirth"
          />
        </div>

        <div class="mb-3">
          <label for="dateOfBirth" class="form-label">
            الجنس <span style="color: red">*</span>
          </label>
          <div style="text-align: right;">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="inlineRadio1"
                disabled
                v-model="body.gender"
                name="gender"
                value="male"
              />

              <label class="form-check-label" for="inlineRadio1">ذكر</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                disabled
                id="inlineRadio1"
                v-model="body.gender"
                name="gender"
                value="female"
              />

              <label class="form-check-label" for="inlineRadio1">انثي</label>
            </div>
          </div>
        </div>
        <div v-if="listEdit == 0" class="mb-3 text-center">
          <p>لا يوجد فرق متاحه لهذا الشخص</p>
        </div>
        <div v-else class="mb-3">
          <label for="teams" class="form-label">
            الفريق <span style="color: red">*</span>
          </label>
          <Fieldset v-for="item of listEdit" :key="item.id">
            <template #legend>
              {{ item.name }} ({{ item.yearsTeams.length }})
            </template>
            <p v-for="itm of item.yearsTeams" :key="itm.id">
              <input
                type="checkbox"
                name="teams[]"
                v-model="body.teams"
                :value="itm"
              />
              - {{ itm.name }}
            </p>
          </Fieldset>
        </div>

        <div class="mb-3">
          <Fieldset legend="المرفقات">
            <div class="row pt-3 container" style="margin: auto;">
              <div class="col-md-6">
                <div class="rowSec LowLabel form-group row">
                  <label class="col-sm-2 col-form-label">
                    الصورة الشخصية <span style="color: red;width: 10px">*</span>
                  </label>
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <label for="imageUpload" class="LabelUploadImg"
                        ><i class="fa fa-upload"></i
                      ></label>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      @change="previewImage($event, 'image')"
                      class="file-upload"
                      name="personal_photo"
                    />
                    <!-- <span
                    style="cursor:pointer;color:red;font-size:20px;font-weight:bold;position: absolute;
    left: 5px;
    top: 0px;z-index:2;"
                    class="remove_image"
                    ><i class="fa fa-times"></i
                  ></span> -->
                    <div class="avatar-preview">
                      <div id="imagePreview" class="imagePreview">
                        <img
                          class="profile-pic selected"
                          :src="body.image"
                          @error="$errorHandlerPlayer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="rowSec LowLabel form-group row">
                  <label class="col-sm-2 col-form-label">
                    شهادة الميلاد <span style="color: red;width: 10px">*</span>
                  </label>
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <label for="imageUpload" class="LabelUploadImg"
                        ><i class="fa fa-upload"></i
                      ></label>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      @change="previewImage($event, 'birthCertificate')"
                      class="file-upload"
                      name="birthCertificate"
                    />
                    <!-- <span
                    style="cursor:pointer;color:red;font-size:20px;font-weight:bold;position: absolute;
    left: 5px;
    top: 0px;z-index:2;"
                    class="remove_image"
                    ><i class="fa fa-times"></i
                  ></span> -->
                    <div class="avatar-preview">
                      <div id="imagePreview" class="imagePreview">
                        <img
                          class="profile-pic"
                          @error="$errorHandlerPlayer"
                          :src="body.birthCertificate"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="rowSec LowLabel form-group row">
                  <label style="width: 150px;" class="col-sm-2 col-form-label"
                    >بطاقه الرقم القومى
                    <span style="font-size: x-small;"
                      >(للاعبين الذين لديهم بطاقه رقم قومي )</span
                    ></label
                  >
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <label for="imageUpload" class="LabelUploadImg"
                        ><i class="fa fa-upload"></i
                      ></label>
                    </div>
                    <input
                      class="file-upload"
                      type="file"
                      accept="image/*"
                      @change="previewImage($event, 'nationalIdCard')"
                      name="nationalIdCard"
                    />
                    <!-- <span
                    style="cursor:pointer;color:red;font-size:20px;font-weight:bold;position: absolute;
    left: 5px;
    top: 0px;z-index:2;"
                    class="remove_image"
                    ><i class="fa fa-times"></i
                  ></span> -->
                    <div class="avatar-preview">
                      <div id="imagePreview" class="imagePreview">
                        <img
                          class="profile-pic"
                          @error="$errorHandlerPlayer"
                          :src="body.nationalIdCard"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="rowSec LowLabel form-group row">
                  <label class="col-sm-2 col-form-label">
                    اثبات القيد للاعب
                    <span style="color: red;width: 10px">*</span></label
                  >
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <label for="imageUpload" class="LabelUploadImg"
                        ><i class="fa fa-upload"></i
                      ></label>
                    </div>
                    <input
                      class="file-upload"
                      type="file"
                      accept="image/*"
                      @change="previewImage($event, 'constraintProve')"
                      name="constraintProve"
                    />
                    <!-- <span
                    style="cursor:pointer;color:red;font-size:20px;font-weight:bold;position: absolute;
    left: 5px;
    top: 0px;z-index:2;"
                    class="remove_image"
                    ><i class="fa fa-times"></i
                  ></span> -->
                    <div class="avatar-preview">
                      <div id="imagePreview" class="imagePreview">
                        <img
                          class="profile-pic"
                          @error="$errorHandlerPlayer"
                          :src="body.constraintProve"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="rowSec LowLabel form-group row">
                  <label class="col-sm-2 col-form-label"
                    >صوره بطاقه الام
                    <span style="color: red;width: 10px">*</span>
                  </label>
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <label for="imageUpload" class="LabelUploadImg"
                        ><i class="fa fa-upload"></i
                      ></label>
                    </div>
                    <input
                      class="file-upload"
                      type="file"
                      accept="image/*"
                      @change="previewImage($event, 'motherCard')"
                      name="motherCard"
                    />
                    <!-- <span
                    style="cursor:pointer;color:red;font-size:20px;font-weight:bold;position: absolute;
    left: 5px;
    top: 0px;z-index:2;"
                    class="remove_image"
                    ><i class="fa fa-times"></i
                  ></span> -->
                    <div class="avatar-preview">
                      <div id="imagePreview" class="imagePreview">
                        <img
                          class="profile-pic"
                          @error="$errorHandlerPlayer"
                          :src="body.motherCard"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="rowSec LowLabel form-group row">
                  <label class="col-sm-2 col-form-label"
                    >صوره بطاقه الاب
                    <span style="color: red;width: 10px">*</span></label
                  >
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <label for="imageUpload" class="LabelUploadImg"
                        ><i class="fa fa-upload"></i
                      ></label>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      @change="previewImage($event, 'fatherCard')"
                      class="file-upload"
                      name="fatherCard"
                    />
                    <!-- <span
                    style="cursor:pointer;color:red;font-size:20px;font-weight:bold;position: absolute;
    left: 5px;
    top: 0px;z-index:2;"
                    class="remove_image"
                    ><i class="fa fa-times"></i
                  ></span> -->
                    <div class="avatar-preview">
                      <div id="imagePreview" class="imagePreview">
                        <img
                          class="profile-pic"
                          @error="$errorHandlerPlayer"
                          :src="body.fatherCard"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fieldset>
        </div>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="حفظ وخروج"
          icon="pi pi-plus"
          :disabled="disabledAdd"
          class="p-ml-2 p-button-success"
          @click="save(true)"
        />
        <Button
          label="حفظ واضافه لاعب اخر"
          icon="pi pi-plus"
          :disabled="disabledAdd"
          class="p-ml-2 p-button-success"
          @click="save(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddPlayers',
  data() {
    const academie = JSON.parse(localStorage.user);
    return {
      body: {
        name: null,
        nameEn: null,
        schoolName: null,
        teams: [],
        image: '',
        academieId: academie?.id,
        leaguesId: null,
        nationalID: null,
        gender: 'male',
        dateOfBirth: null,
        nationalIdCard: '',
        birthCertificate: '',
        constraintProve: '',
        motherCard: '',
        fatherCard: '',
      },
      academie,
      listEdit: [],
      canAddInfo: false,
      disabledAdd: false,
    };
  },
  props: {
    leaguesId: Number,
  },
  methods: {
    previewImage(ev, name) {
      this.$file2base64(ev, (image) => {
        this.body[name] = image;
      });
    },
    uploadIcon(ev) {},
    save(ex) {
      if (
        this.body.name &&
        this.body.nameEn &&
        this.body.schoolName &&
        this.body.nationalID &&
        this.body.dateOfBirth &&
        this.body.image &&
        // this.body.nationalIdCard &&
        this.body.birthCertificate &&
        this.body.constraintProve &&
        this.body.motherCard &&
        this.body.fatherCard &&
        this.body.nationalID &&
        this.body.teams.length > 0
      ) {
        this.disabledAdd = true;
        this.$http.post(`players`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافه بنجاح',
              life: 3000,
            });

            if (ex) this.$router.push('/home/' + this.body.leaguesId);
            else {
              this.body.name = null;
              this.body.nameEn = null;
              this.body.schoolName = null;
              this.body.teams = [];
              this.body.image = '';
              this.body.nationalID = null;
              this.body.gender = 'male';
              this.body.dateOfBirth = null;
              this.body.nationalIdCard = '';
              this.body.birthCertificate = '';
              this.body.constraintProve = '';
              this.body.motherCard = '';
              this.body.fatherCard = '';

              this.listEdit = [];
            }
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
    getYears() {
      this.$http
        .get(
          `years/findAllYearsAcademieByDate?leaguesId=${this.body.leaguesId}&academieId=${this.body.academieId}&dateOfBirth=${this.body.dateOfBirth}`,
        )
        .then(
          (res) => {
            this.listEdit = res.data;
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
  },
  created() {
    this.body.leaguesId = this.leaguesId;
  },

  watch: {
    'body.nationalID'(val) {
      console.log('4444444444',val)

      let national = '';
      let year2 = '';
      let month = '';
      let day = '';
      if (val.length == 14 || val.length == 13) {
        national = val;
        const year = national.charAt(0);
        year2 += national.charAt(1);
        year2 += national.charAt(2);
        month += national.charAt(3);
        month += national.charAt(4);
        day += national.charAt(5);
        day += national.charAt(6);

        if (year == 1) {
          return;
        } else if (year == 2) {
          this.body.dateOfBirth = '19' + year2 + '-' + month + '-' + day;
        } else if (year == 3) {
          this.body.dateOfBirth = '20' + year2 + '-' + month + '-' + day;
        }
        console.log('4444444444',this.body.dateOfBirth)
        this.getYears();
        const gender = national.charAt(12);

        if (
          gender == 1 ||
          gender == 3 ||
          gender == 5 ||
          gender == 7 ||
          gender == 9
        ) {
          this.body.gender = 'male';
        } else if (
          gender == 0 ||
          gender == 2 ||
          gender == 4 ||
          gender == 6 ||
          gender == 8
        ) {
          this.body.gender = 'female';
        } else {
          return;
        }


        this.$http
          .post(`players/search`, {
            nationalID: val,
          })
          .then(
            (res) => {
              if (res.data && res.data.length > 0) {
                const user = res.data[0];
                if (
                  user.leaguesId &&
                  user.leaguesId.id == this.body.leaguesId
                ) {
                  this.$toast.add({
                    severity: 'error',
                    summary: 'هناك خطأ',
                    detail:
                      'هذا اللاعب مسجل فى اكاديميه اخرى الرجاء التواصل مع اداره البطوله',
                    life: 3000,
                  });
                  this.body.name = null;
                  this.body.nameEn = null;
                  this.body.schoolName = null;
                  this.body.teams = [];
                  this.body.image = '';
                  this.body.gender = 'male';
                  this.body.nationalIdCard = '';
                  this.body.birthCertificate = '';
                  this.body.constraintProve = '';
                  this.body.motherCard = '';
                  this.body.fatherCard = '';
                } else {
                  this.body.name = user.name;
                  this.body.nameEn = user.nameEn;
                  this.body.schoolName = user.schoolName;

                  this.body.image = this.$baseUploadURL + user.image;
                  this.body.nationalIdCard =
                    this.$baseUploadURL + user.nationalIdCard;
                  this.body.birthCertificate =
                    this.$baseUploadURL + user.birthCertificate;
                  this.body.constraintProve =
                    this.$baseUploadURL + user.constraintProve;
                  this.body.motherCard = this.$baseUploadURL + user.motherCard;
                  this.body.fatherCard = this.$baseUploadURL + user.fatherCard;
                }
              } else {
                this.body.name = null;
                this.body.nameEn = null;
                this.body.schoolName = null;
                this.body.teams = [];
                this.body.image = '';
                this.body.gender = 'male';
                this.body.nationalIdCard = '';
                this.body.birthCertificate = '';
                this.body.constraintProve = '';
                this.body.motherCard = '';
                this.body.fatherCard = '';
              }
              this.canAddInfo = true;
              this.$eventHub.$emit('getData', 'c');
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      }
    },
  },
};
</script>

<style>
.rowSec {
  margin-bottom: 50px;
}

.rowSec.LowLabel label {
  vertical-align: top;
  min-width: 300px;
  line-height: 20px;
}

.avatar-upload {
  position: relative;
  margin: 0;
  display: inline-block;
  width: calc(100% - 180px);
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  width: 20px;
  top: 10px;
  z-index: 99;
}
.file-upload {
  width: 100%;
  opacity: 0;
  position: absolute;
  z-index: 999;
  height: 100%;
}

.avatar-upload .avatar-edit label.LabelUploadImg {
  display: inline-block;
  width: 34px;
  min-width: 34px !important;
  text-align: center !important;
  line-height: 32px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #0a8072;
  color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-preview {
  width: 100%;
  height: 150px;
  position: relative;
  border-radius: 0;
  border: 1px solid #ccc;
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.imagePreview img {
  width: 100%;
  height: 100%;
}
</style>
