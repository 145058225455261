<template>
  <div>
    <section class="headSec">
      <img src="../../assets/bg.jpg" />
      <div class="overLay"></div>
      <h2>تسجيل اللاعبين والمدربين فى الدوري</h2>
    </section>
    <div class="Container_Details pt-5">
      <div class="container bg-white rounded py-5">
        <TabView :activeIndex="active">
          <TabPanel header="اضافه لاعب" :disabled="!user.lockAdd">
            <AddPlayers :leaguesId="leaguesId" />
          </TabPanel>
          <TabPanel
            header="اضافه الجهاز الفنى والادارى"
            :disabled="!user.lockAdd"
          >
            <AddCoach :leaguesId="leaguesId" />
          </TabPanel>
          <TabPanel header="عرض المسجلين">
            <TabView>
              <TabPanel header="قائمه اللاعبين">
                <PlayersList :id="leaguesId" />
              </TabPanel>
              <TabPanel header="قائمه المدربين">
                <CoachList :id="leaguesId" />
              </TabPanel>
              <TabPanel header="قائمه الادريين">
                <MindfulList :id="leaguesId" />
              </TabPanel>

              <TabPanel header="قائمه المدربين الفنين">
                <TechnicalManagerList :id="leaguesId" />
              </TabPanel>
            </TabView>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script>
import AddPlayers from './players/add.vue';
import AddCoach from './coach/add.vue';
import CoachList from './coach/index.vue';
import MindfulList from './mindful/index.vue';
import TechnicalManagerList from './technical_manager/index.vue';
import PlayersList from './players/index.vue';

export default {
  components: {
    AddPlayers,
    AddCoach,
    CoachList,
    MindfulList,
    TechnicalManagerList,
    PlayersList,
  },

  data() {
    return {
      leaguesId: null,
      user: {
        lockAdd: false,
        lockUpdate: false,
        lockDelete: false,
      },
      active: 0,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.leaguesId = this.$route.params.id;
    }

    if (localStorage.user) {
      const user = JSON.parse(localStorage.user);

      this.$http.get(`academies/${user.id}`).then(
        (res) => {
          this.user = res.data;

          this.active = this.user.lockAdd ? 0 : 2;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
 
};
</script>

<style>
.Container_Details {
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: #0a8072;
}

.headSec {
  position: relative;
  overflow: hidden;
  height: 230px;
}

.headSec img {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.headSec .overLay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #000000;
  opacity: 0.7;
}

.headSec h2 {
  position: absolute;
  top: 46%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
}
</style>
