<template>
  <div>
    <!-- <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافه جديد"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          @click="$router.push('/user/technical_manager-add/' + id)"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/user')"
        />
      
      </template>
    </Toolbar> -->

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="100"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه المديرين الفنين

          <div style="text-align:left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />

      <Column
        field="image"
        header="الصوره الشخصيه"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img :src="$baseUploadURL + slotProps.data.image" class="dt-image" />
        </template>
      </Column>
      <Column field="name" header="الاسم" :sortable="true" />
      <Column field="nationalID" header="الرقم القومي" :sortable="true" />

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"

            :disabled="!user.lockUpdate"
            @click="
              $router.push('/user/technical_manager-edit/' + slotProps.data.id)
            "
            icon="pi pi-pencil"
            class="p-button-success p-ml-2  p-button-rounded"  v-tooltip="'تعديل'"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            :disabled="!user.lockDelete"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded" v-tooltip="'حذف'"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.teams"
            class="p-datatable-customers"
          >
            <Column field="yearsAcademieId.yearId.name" header="السنه" />
            <Column field="name" header="الفرقه" />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        lockAdd: false,
        lockUpdate: false,
        lockDelete: false,
      },
      expandedRows: [],
      // id: null,
      list: [],
      selectedItems: [],
      selectedColumns: [],
      columns: [
        {
          field: 'name',
          header: 'الاسم',
        },
        {
          field: 'teams',
          header: 'الفرق المشاركه',
        },
      ],
      loading: true,
      user: {
        lockAdd: false,
        lockUpdate: false,
        lockDelete: false,
      },
    };
  },

  props: {
    id: Number,
  },
  methods: {
    getData() {
      const user = JSON.parse(localStorage.user);
      this.$http
        .get(`technicalManager?leaguesId=${this.id}&academieId=${user?.id}`)
        .then(
          (response) => {
            this.loading = false;
            this.list = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .post(`technicalManager/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`technicalManager/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    // this.id = this.$route.params.id;
    this.getData();
    this.selectedColumns = this.columns;

    if (localStorage.user) {
      const userx = JSON.parse(localStorage.user);

      this.$http.get(`academies/${userx.id}`).then(
        (res) => {
          this.user = res.data;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }

    this.$eventHub.$on('getData', () => {
      this.getData();
    });
  },
};
</script>

<style></style>
